<template>
  <page-skeleton theme="light">
    <section-half-image
        arrow
        scroll-to="comprehensive-solution-arrow"
        hero
        small-photo
        img-background="gradient"
        :alt="$t('solutions.complete.images.alt1')"
        image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/hero_dJho46Oo56.png?updatedAt=1635404476810"
    >
      <template #header>
        {{$t('solutions.complete.hero.header')}}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('solutions.complete.hero.text') }}
        </div>
      </template>
    </section-half-image>
    <section-half-image
        background="light"
        small-photo
        photo-right-align
        round-radius
        id="comprehensive-solution-arrow"
        :alt="$t('solutions.complete.images.alt2')"
        image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/qc_with_red_lighting_Nk5PmQmJ-I.jpg?updatedAt=1635404527294"
    >
      <template #header>
        {{$t('solutions.complete.section1.header')}}
      </template>
      <template #content>
        <div class="mb-3">
          {{ $t('solutions.complete.section1.text') }}
        </div>
        <ul class="red-markers mb-0">
          <li>
            <i18n path="solutions.complete.section1.offer1" tag="p">
              <template v-slot:checker>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('solutions.complete.section1.checker') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="solutions.complete.section1.offer2" tag="p">
              <template v-slot:handler>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('solutions.complete.section1.handler') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="solutions.complete.section1.offer3" tag="div">
              <template v-slot:central>
                  <span class="i-primary i-font-text-bold">
                    {{ $t('solutions.complete.section1.central') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
      </template>
    </section-half-image>
    <full-width-image image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/isotronic_system_with_engineers_MSAIhzBlyf.jpg?updatedAt=1635404569539"
                      :alt="$t('solutions.complete.images.alt3')"
    />
    <section-half-image
        small-photo
        img-background="solid"
        :alt="$t('solutions.complete.images.alt4')"
        image-path="checker_720p.mp4"
        video
    >
      <template #header>
        <i18n path="solutions.complete.section2.header" tag="div">
          <template v-slot:headerGrey>
            <div class="i-secondary header-grey">
              {{ $t('solutions.complete.section2.headerGrey') }}
            </div>
          </template>
        </i18n>
      </template>
      <template #content>
        <div>
          {{ $t('solutions.complete.section2.text') }}
        </div>
        <div class="mt-3 mb-1 i-primary i-font-text-bold">
          {{ $t('solutions.complete.section2.offer1.title') }}
        </div>
        <ul>
          <li>
            <i18n path="solutions.complete.section2.offer1.sub1" tag="div">
              <template v-slot:geometry>
                  <i-link link="Geometric Defects">
                    {{ $t('solutions.complete.section2.offer1.geometry') }}
                  </i-link>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="solutions.complete.section2.offer1.sub2" tag="div">
              <template v-slot:cosmetic>
                <i-link link="Cosmetic Defects">
                  {{ $t('solutions.complete.section2.offer1.cosmetic') }}
                </i-link>
              </template>
            </i18n>
          </li>
          <li>
            {{$t('solutions.complete.section2.offer1.sub3')}}
          </li>
        </ul>
        <div class="mt-3 mb-1 i-primary i-font-text-bold">
          {{ $t('solutions.complete.section2.offer2.title') }}
        </div>
        <ul>
          <li>
            {{$t('solutions.complete.section2.offer2.sub1')}}
          </li>
        </ul>
        <div class="mt-3 mb-1 i-primary i-font-text-bold">
          {{ $t('solutions.complete.section2.offer3.title') }}
        </div>
        <ul class="mb-0">
          <li>
            {{$t('solutions.complete.section2.offer3.sub1')}}
          </li>
        </ul>
      </template>
    </section-half-image>
    <central />
    <full-width-image image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/complete_red_lighting_0qsyPLN0FQ.jpg?updatedAt=1635404780634"
                      :alt="$t('solutions.complete.images.alt2')"
    />
    <section-half-image
        background="light"
        small-photo
        round-radius
        :alt="$t('solutions.complete.images.alt5')"
        image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/vial_being_inspected_eFmU3dAkJR.jpg?updatedAt=1635404830820"
    >
      <template #header>
        <i18n path="solutions.complete.section4.header" tag="h2">
          <template v-slot:headerGrey>
            <div class="i-secondary header-grey">
              {{ $t('solutions.complete.section4.headerGrey') }}
            </div>
          </template>
        </i18n>
      </template>
      <template #content>
        <div>
          {{ $t('solutions.complete.section4.text') }}
        </div>
        <div class="my-4">
          {{ $t('solutions.complete.section4.text2') }}
        </div>
        <ul class="red-markers mb-0">
          <li>
            <i18n path="solutions.complete.section4.offer1" tag="p">
              <template v-slot:product>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('solutions.complete.section4.product') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li>
            <i18n path="solutions.complete.section4.offer2" tag="div">
              <template v-slot:camera>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('solutions.complete.section4.camera') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
      </template>
    </section-half-image>
    <section-half-image
        small-photo
        img-background="solid"
        photo-right-align
        :alt="$t('solutions.complete.images.alt6')"
        image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/server_ui__wDm5weXA8Q.png?updatedAt=1635404874023"
    >
      <template #header>
        {{$t('solutions.complete.section5.header')}}
      </template>
      <template #content>
        <div class="mb-3">
          {{ $t('solutions.complete.section5.text') }}
        </div>
        <ul>
          <li class="i-font-text-bold i-secondary">
            {{$t('solutions.complete.section5.offer1')}}
          </li>
          <li class="i-font-text-bold i-secondary">
            {{$t('solutions.complete.section5.offer2')}}
          </li>
          <li class="i-font-text-bold i-secondary">
            {{$t('solutions.complete.section5.offer3')}}
          </li>
          <li class="i-font-text-bold i-secondary">
            {{$t('solutions.complete.section5.offer4')}}
          </li>
          <li class="i-font-text-bold i-secondary">
            {{$t('solutions.complete.section5.offer5')}}
          </li>
        </ul>
        <div class="mt-3">
          <i18n path="solutions.complete.section5.text2" tag="span">
            <template v-slot:space>
                  <div class="my-3"></div>
            </template>
          </i18n>
        </div>
      </template>
    </section-half-image>
    <full-width-image image-path="https://ik.imagekit.io/isotronic/solutions/comprehensive/vials_in_container_R1rhYKrIgh.jpg?updatedAt=1635404924112"
                      :alt="$t('solutions.complete.images.alt7')"
    />
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import iLink from "@/components/utility/SiteLink";
import Central from "@/components/solutions/comprehensiveSolution/IsotronicCentral"

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    iLink,
    Central
  },
  metaInfo() {
    return {
      title: this.$t('solutions.complete.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('solutions.complete.meta.description')}
      ]
    }
  },
}
</script>

<style lang="sass" scoped>
.no-margin
  /deep/ p
    margin-bottom: 0!important
.red-markers /deep/ li
  &::marker
    color: $primary-color!important
    font-size: 1.3rem

.header-grey
  text-transform: none!important
</style>
