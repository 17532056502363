<template>
  <b-row class="justify-content-center i-border i-outer-padding i-vertical-no-photo central-section">
    <b-col cols="12" sm="9" md="6"
           class="text-uppercase text-left text-sm-center">
      <i18n path="solutions.complete.section3.header" tag="h2">
        <template v-slot:headerGrey>
          <div class="i-secondary header-grey">
            {{ $t('solutions.complete.section3.headerGrey') }}
          </div>
        </template>
      </i18n>
    </b-col>
    <div class="w-100" />
    <b-col cols="12" class="pt-3 i-header-section-margin text-left text-sm-center">
      <div class="i-secondary i-text">
        {{ $t('solutions.complete.section3.subheader') }}
      </div>
    </b-col>
    <b-col v-for="(feature, index) in features"
           :key="'feature'+index"
           cols="12" md="6" lg="5">
      <feature-tile
          :src="feature.src"
          :alt="feature.alt"
      >
        <template #header>
          {{feature.title}}
        </template>
        <template #subheader>
          {{feature.text}}
        </template>
      </feature-tile>
    </b-col>
    <div class="w-100 my-4"></div>
    <b-col cols="12" lg="10">
      <picture>
        <source media="(max-width: 992px)"
                srcset="https://ik.imagekit.io/isotronic/solutions/comprehensive/tall_central_P2rPYAhR7w.png?updatedAt=1635404662021">
        <source media="(min-width: 993px)"
                srcset="https://ik.imagekit.io/isotronic/solutions/comprehensive/wide_central_YJ0NcI8kVy.png?updatedAt=1635404661965">
        <img
            class="w-100"
            src="https://ik.imagekit.io/isotronic/solutions/comprehensive/tall_central_P2rPYAhR7w.png?updatedAt=1635404662021"
            :alt="$t('solutions.complete.images.alt8')">
      </picture>
    </b-col>
  </b-row>
</template>

<script>
import FeatureTile from "@/components/utility/FeatureTile"

export default {
  components: {
    FeatureTile
  },
  //todo : go over these points and edit them
  data() {
    return {
      features: [
        {
          title: this.$t('solutions.complete.section3.features.feature1.title'),
          text: this.$t('solutions.complete.section3.features.feature1.text'),
          alt: this.$t('solutions.complete.section3.features.feature1.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons_Fleet_Overview_copy_2_bFCuQZTMc.svg?updatedAt=1631202480331',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature2.title'),
          text: this.$t('solutions.complete.section3.features.feature2.text'),
          alt: this.$t('solutions.complete.section3.features.feature2.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-certificate_1_ivq1l9MbR.svg?updatedAt=1631202480967',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature3.title'),
          text: this.$t('solutions.complete.section3.features.feature3.text'),
          alt: this.$t('solutions.complete.section3.features.feature3.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-index_1_wsJy46GIgk.svg?updatedAt=1631202480388',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature4.title'),
          text: this.$t('solutions.complete.section3.features.feature4.text'),
          alt: this.$t('solutions.complete.section3.features.feature4.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/Frame_MJnOJOr9iOY.svg?updatedAt=1631202481777',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature5.title'),
          text: this.$t('solutions.complete.section3.features.feature5.text'),
          alt: this.$t('solutions.complete.section3.features.feature5.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/Frame-1_bZlFDjDpCC.svg?updatedAt=1631202481602',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature6.title'),
          text: this.$t('solutions.complete.section3.features.feature6.text'),
          alt: this.$t('solutions.complete.section3.features.feature6.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-radio-waves_1_9V-ICe_ah.svg?updatedAt=1631202480357',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature7.title'),
          text: this.$t('solutions.complete.section3.features.feature7.text'),
          alt: this.$t('solutions.complete.section3.features.feature7.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-document_1_W05FSikMo.svg?updatedAt=1631202481334',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature8.title'),
          text: this.$t('solutions.complete.section3.features.feature8.text'),
          alt: this.$t('solutions.complete.section3.features.feature8.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-doughnut-chart_1_sM1_Z-mdBedO.svg?updatedAt=1631202480660',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature9.title'),
          text: this.$t('solutions.complete.section3.features.feature9.text'),
          alt: this.$t('solutions.complete.section3.features.feature9.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/icons8-automation_1_T4uGOJ0AVB0.svg?updatedAt=1631202481119',
        },
        {
          title: this.$t('solutions.complete.section3.features.feature10.title'),
          text: this.$t('solutions.complete.section3.features.feature10.text'),
          alt: this.$t('solutions.complete.section3.features.feature10.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/comprehensive/central/Frame-2_6zLK5Xc34D.svg?updatedAt=1631202481338',
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.header-grey
  text-transform: none!important

.central-section
  background: rgba(100, 101, 104, 0.05)
</style>
